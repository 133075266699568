import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, alpha } from '@mui/material';
import nft from "../../../assets/images/NFT.svg"
function createData(Alpha, Price, Currently, reviews, subscripe, Action) {
  return {
    Alpha,
    Price,
    Currently,
    reviews,
    subscripe,
    Action,
    nft,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const textSubscripestyle = {
    color: "#ffffff",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "var(--font-family)",
    borderBottom:"unset !important",
    padding:"16px 0px 16px 0px"
}
const textTitlestyle = {
    color: "#ffffff",
    fontWeight: "600",
    fontSize: "16px",
    fontFamily: "var(--font-family)",

}

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset  !important',background:"#1D1932"} }}>
        <TableCell>
         
        </TableCell>
        <TableCell component="th" scope="row" sx={{...textSubscripestyle}}>
            <Box
            sx={{
                display:"flex",
                alignItems:"center",
                gap:"12px",
                whiteSpace:"nowrap"
            }}
            > 
          <img src={row.nft}></img>
          {row.Alpha} 

          </Box>
        </TableCell>
        <TableCell align="center" sx={{...textSubscripestyle}} >{row.Price}</TableCell>
        <TableCell align="center" sx={{...textSubscripestyle}}>{row.Currently}</TableCell>
        <TableCell align="center" sx={{...textSubscripestyle}}>{row.reviews}</TableCell>
        <TableCell align="center" sx={{...textSubscripestyle}}>
            <Button 
            sx={{ 
                color: "#fff",
                 fontSize: "14px",
                  fontWeight: "400",
                   lineHeight: "21px",
                    fontFamily: "var(--font-family)",
                     textTransform: "unset",
                     border:"1px solid  #6F4FF2",
                     padding:"8px 12px 8px 12px",
                      }}>
                                        {row.subscripe}
                                    </Button>
                                    </TableCell>
        <TableCell align="center" sx={{...textSubscripestyle}}> <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon sx={{color:"#fff"}}/> : <KeyboardArrowDownIcon  sx={{color:"#fff"}}/>}
          </IconButton></TableCell>

      </TableRow>
      <TableRow  sx={{ '& > *': { borderBottom: 'unset !important',background:"#1D1932"} }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography sx={{...textTitlestyle}} variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{...textSubscripestyle}}>Date</TableCell>
                    <TableCell sx={{...textSubscripestyle}}>Customer</TableCell>
                    <TableCell sx={{...textSubscripestyle}} align="center">Amount</TableCell>
                    <TableCell sx={{...textSubscripestyle}} align="center">Total price ($)</TableCell>
                    <TableCell sx={{...textSubscripestyle}} align="center">Total price ($)</TableCell>
                    <TableCell sx={{...textSubscripestyle}} align="center">Total price ($)</TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell sx={{...textSubscripestyle}} component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell sx={{...textSubscripestyle}}>{historyRow.customerId}</TableCell>
                      <TableCell sx={{...textSubscripestyle}} align="center">{historyRow.amount}</TableCell>
                      <TableCell sx={{...textSubscripestyle}} align="center">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                      <TableCell sx={{...textSubscripestyle}} align="center">{historyRow.amount}</TableCell>

                      <TableCell sx={{...textSubscripestyle}} align="center">{historyRow.amount}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Alpha: PropTypes.number.isRequired,
    Price: PropTypes.number.isRequired,
    Currently: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Cute Cube Cool', 159, 6.0, 24, 'Subscribe',4),
  createData('Cute Cube Cool', 237, 9.0, 37, 'Subscribe', 4.99),
  createData('Cute Cube Cool', 262, 16.0, 24, 'Subscribe', 3.79),
  createData('Cute Cube Cool', 305, 3.7, 67, 'Subscribe', 2.5),
  createData('Cute Cube Cool', 356, 16.0, 49, 'Subscribe', 1.5),
  
];

export default function CollapsibleTable() {
    const textTitlestyle = {
        color: "#ffffff",
        fontWeight: "600",
        fontSize: "16px",
        fontFamily: "var(--font-family)"
    }
 
  return (
    <TableContainer  sx={{ overflowX: 'auto' }}>
      <Table aria-label="collapsible table">
        <TableHead
        sx={{
            backgroundColor:"rgba(20,17,42,255)"
        }}
        >
          <TableRow  sx={{ '& > *': { borderBottom: 'unset !important',background:"transparent"} }}>
            <TableCell />
            <TableCell align='left' sx={{...textTitlestyle}}>Alpha Caller</TableCell>
            <TableCell align="center" sx={{...textTitlestyle}}>Price</TableCell>
            <TableCell align="center" sx={{...textTitlestyle}}>Currently</TableCell>
            <TableCell align="center" sx={{...textTitlestyle}}>Reviews</TableCell>
            <TableCell align="center" sx={{...textTitlestyle}}>Subscribe</TableCell>
            <TableCell align="center" sx={{...textTitlestyle}}>Action</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
  {rows.map((row, index) => (
    <React.Fragment key={row.name}>
      <Row key={row.name} row={row} />
      {index !== rows.length - 1 && <div style={{ marginTop:"20px"}}></div>}
    </React.Fragment>
  ))}
</TableBody>

      </Table>
    </TableContainer>
  );
}
