import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../../assets/styles/variables.css"
import BgImg from "../../assets/images/FaqBg.svg"
const Faq = () => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const accordinationData = [
        { name: "accord  1", details: "details1" },
        { name: "accord  2", details: "details2" },
        { name: "accord  3", details: "details3" }
    ]
    return (
        <>
            <Grid
                sx={{
                    marginTop: {
                        lg: "173px",
                        md: "173px",
                        sm: "73px",
                        xs: "80px"
                    },
                    paddingRight: {
                        lg: "120px",
                        md: "120px",
                        sm: "60px",
                        xs: "13px"

                    },
                    paddingLeft: {
                        lg: "120px",
                        md: "120px",
                        sm: "60px",
                        xs: "13px"
                    },
                    backgroundImage: `url(${BgImg})`,
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    backgroundRepeat: "no-repeat",


                }}
            >

                <Typography
                    sx={{
                        color: "#ffffff",
                        fontSize: {
                            lg: "64px",
                            md: "64px",
                            sm: "42px",
                            xs: "32px"
                        },
                        fontWeight: "500",
                        lineHeight: "130px",
                        fontFamily: "var(--font-family)",
                        textAlign: "center"


                    }}
                >
                    FAQs
                </Typography>
                {/* accordination */}
                <div>
                    {accordinationData.map((item) => (
                        <Accordion
                            sx={{
                                marginBottom: "24px",
                                borderRadius: "9px !important",
                                backgroundColor: "#1D1932",
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontWeight: "400",
                                        fontFamily: 'var(--font-family)',
                                        fontSize: {
                                            lg: "25px",
                                            md: "25px",
                                            sm: "20px",
                                            xs: "18px"
                                        }
                                    }}
                                >{item.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    background: "#D9D9D94D",
                                    borderRadius: "9px"
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontWeight: "400",
                                        fontFamily: 'var(--font-family)',
                                        fontSize: {
                                            lg: "25px",
                                            md: "25px",
                                            sm: "20px",
                                            xs: "18px"
                                        }
                                    }}
                                >
                                    {item.details}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </Grid>
        </>
    )
}

export default Faq