import { Box, Grid, Typography, breadcrumbsClasses } from "@mui/material";
import React from "react";

const textTitlestyle = {
    color: "#ffffff",
    fontWeight: "600",
    fontSize: {
        lg: "16px",
        md: "16px",
        sm: "16px",
        xs: "15px"
    },
    fontFamily: "var(--font-family)"
}
const textSubscripestyle = {
    color: "#ffffff",
    fontWeight: "400",
    fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
        xs: "12px"
    },
    fontFamily: "var(--font-family)"
}
export default function HeaderDashboardSection({
    title,
    description,
    breadCrambs1,
    breadCrambs2
}) {


    return (
        <>
            <Grid
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: {
                        lg: "30px",
                        md: "30px",
                        sm: "30px",
                        xs: "60px"
                    },
                    flexWrap: "wrap"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px"
                    }}
                >
                    <Typography
                        sx={{
                            ...textTitlestyle
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            ...textSubscripestyle
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: "8px"
                    }}
                >
                    <Typography
                        sx={{
                            ...textSubscripestyle,
                            color: "#6C7AA0 !important"

                        }}
                    >
                        {breadCrambs1}
                    </Typography>
                    <span
                        style={{
                            ...textSubscripestyle

                        }}
                    >     {">"}                </span>
                    <Typography
                        sx={{
                            ...textSubscripestyle,
                            color: "#6C7AA0 !important"


                        }}
                    >
                        {breadCrambs2}

                    </Typography>
                </Box>

            </Grid>

        </>
    )
}