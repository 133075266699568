import React from "react";
import { styled } from '@mui/material/styles';
import { Box } from "@mui/material";
import ProfileInputs from "./profileInputs";

export default function Profile() {
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(2, 1),
        ...theme.mixins.toolbar,
    }));

    return (

        <>
            <Box component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                }} >
                <DrawerHeader />
                <ProfileInputs />

            </Box>

        </>
    )
}