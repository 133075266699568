import * as React from 'react';
import { useMediaQuery, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SvgDashboard from '../../assets/images/dashboardsvg';
import SvgList from '../../assets/images/list.svg';
import SvgHeart from '../../assets/images/heart.svg';
import SvgPremuim from '../../assets/images/Premium.svg';
import SvgProfile from '../../assets/images/Profile.svg';
import SvgSettings from '../../assets/images/setting.svg';
import SvgSignOut from '../../assets/images/sign-out.svg';
import "../../assets/styles/variables.css"
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Alert from '../../assets/images/bell.svg';
import profileBg from '../../assets/images/profileBg.svg';
import StatisticSection from './dashboardSection/statisticsSection';
import CallerActivity from './dashboardSection/activityCaller/activityCaller';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;
const SideBarTextStyle = {
    color: "#FFFFFF",
}

const SearchBar = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: "8px",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,

}));
const tabsStyle = {
    '& .css-1aquho2-MuiTabs-indicator': {
        backgroundColor: "unset",
        background: "#D9D9D933",
        height: "80%",
        borderRadius: "50px",
        top: "4px",

    }
};

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
            '& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper': {
                background: "#1D1932"
            },
            '& .css-10hburv-MuiTypography-root': {
                fontFamily: "var(--font-family)"
            },
            '& .css-1l8j5k8': {
                backgroundColor: "#1D1932 !important"
            }
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
            '& .css-12i7wg6-MuiPaper-root-MuiDrawer-paper': {
                background: "#1D1932"
            },
            '& .css-1l8j5k8': {
                backgroundColor: "#1D1932 !important"
            }
        }),
    }),
);

export default function MiniDrawer() {
    const navigate = useNavigate()
    const navigateToDashboard = () => {
        navigate('/dashboard')
    }
    const navigateToList = () => {
        navigate('/list')
    }
    const navigateToProfile = () => {
        navigate('/profile')
    }
    const navigateToFavorite = () => {
        navigate('/Subscription')
    }
    const navigateToTable = () => {
        navigate('/Table')
    }

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects if the screen size is small (mobile).

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar color=''
                sx={{
                    boxShadow: "unset",
                    paddingTop: "10px",
                    background: "rgba(20,17,42,255)",
                    zIndex: "1",
                    paddingBottom:"10px"
                }}
                open={open}>
                <Toolbar 
               
                >
                    {/* <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            display: 'none'

                        }}
                    >
                        <MenuIcon sx={{ color: "#FFFFFF" }} />
                    </IconButton> */}

                    <Grid container 
          sx={{
            paddingLeft: !open ? "60px" : '0px',
        }}
                    >
                        <Grid lg={3} md={3} sm={3} xs={11.5}
                            sx={{
                                display: isMobile ? "flex" : "unset",
                                gap: "10px"
                            }}
                        >
                            <SearchBar
                            sx={{
                                minHeight:"50px",
                                display:"flex",
                                alignItems:"center"
                            }}
                            >
                                <SearchIconWrapper>
                                    <SearchIcon sx={{color:"#fff"}}/>
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                    sx={{
                                        color:"#fff"
                                    }}
                                />
                            </SearchBar>
                            <Box
                                sx={{
                                    display: {
                                        lg: "none",
                                        md: "none",
                                        sm: "none",
                                        xs: "flex"
                                    },
                                    gap: "21px"
                                }}
                            >

                                <img src={Alert}></img>
                                <img src={profileBg}></img>

                            </Box>
                        </Grid>
                        <Grid container lg={9} md={9} sm={9} xs={12}
                            sx={{
                                justifyContent: isMobile ? "center" : "end",
                                marginTop:{
                                    lg:"0px",
                                    md:"0px",
                                    sm:"0px",
                                    xs:"10px"
                                },
                                alignItems:"center"

                            }}
                        >

                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "space-between" }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example"
                                        sx={{
                                            background: "#D9D9D91A",
                                            borderRadius: "50px",
                                            ...tabsStyle,
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <Tab label="12.65 SOL" value="1" sx={{
                                            color: "#FFFFFF !important", fontSize: "15px", height: "auto",

                                        }}
                                            disableRipple
                                        />
                                        <Tab label="3EWx...nzvW" value="2" sx={{ color: "#FFFFFF !important", fontSize: "15px", height: "10px" }}
                                            disableRipple
                                        />
                                    </TabList>
                                </Box>
                                <Box sx={{ flexGrow: 0.1 }} />
                                <Box
                                    sx={{
                                        gap: "21px",
                                        display: isMobile ? "none" : "flex"
                                    }}
                                >

                                    <img src={Alert}></img>
                                    <img src={profileBg}></img>

                                </Box>

                            </TabContext>
                        </Grid>


                    </Grid>


                </Toolbar>
            </AppBar>
            <Drawer

                variant="permanent" open={open}
                sx={{
                    position: "relative"
                }}
            >
                {!isMobile && (
                    <div style={{ position: 'relative', textAlign: 'center' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                ...(open && { display: 'none' }),
                                top: "20px",
                                position: "absolute",
                                right: "50%",
                                transform: "translateX(50%)"
                            }}
                        >
                            <MenuIcon sx={{ color: "#FFFFFF" }} />
                        </IconButton>
                    </div>
                )}
                <DrawerHeader>

                    <IconButton onClick={handleDrawerClose}>
                        {open ? <ChevronRightIcon sx={{ color: "#FFFFFF" }} /> : ""}
                        {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
                    </IconButton>
                </DrawerHeader>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        marginTop: "57px"
                    }}
                >

                    <List
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px"
                        }}
                    >

                        <ListItem onClick={navigateToDashboard} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <SvgDashboard />
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: open ? 1 : 0, ...SideBarTextStyle }} >
                                    Dashboard
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={navigateToList} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}

                                >
                                    <img src={SvgList}></img>
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: open ? 1 : 0, ...SideBarTextStyle }}>
                                    List
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>

                        <ListItem
                            onClick={navigateToTable}
                            disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={SvgHeart}></img>
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: open ? 1 : 0, ...SideBarTextStyle }} >
                                    Favorite
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            onClick={navigateToFavorite}
                            disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={SvgPremuim}></img>
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: open ? 1 : 0, ...SideBarTextStyle }} >
                                    Premuim
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>

                        <ListItem
                            onClick={navigateToProfile}
                            disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={SvgProfile}></img>
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: open ? 1 : 0, ...SideBarTextStyle }} >
                                    Profile
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>


                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={SvgSettings}></img>
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: open ? 1 : 0, ...SideBarTextStyle }} >
                                    Settings
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>


                    </List>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <List>
                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={SvgSignOut}></img>
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: open ? 1 : 0, ...SideBarTextStyle }} >
                                    Sing Out
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            {/* <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <TabContext value={value}> 
                <TabPanel value="1">Item One</TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
        </TabContext>
            </Box> */}
        </Box>
    );
}