import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LoginGoogle from './googleLogin';
import TelegramLoginButton from './loginByTelegram';
import MyComponent from './loginByTelegram';
import { Link } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:{lg:"400px",xs:"70%"},
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius:"16px",
  border:"none"
};

export default function LoginModal() {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <div>

      <Link to="/dashboard">Sign In</Link>
      {/* <Button onClick={handleOpen}>Sign in</Button> */}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <LoginGoogle/>
        <MyComponent/>
        </Box>
      </Modal> */}
    </div>
  );
}