import * as React from "react"
import "./twitter.css"
const TwitterSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={58}
    height={57}
    fill="none"
    {...props}
  >
    <rect width={57.772} height={57} fill="#F3F3F3" rx={28.5} />
    <g clipPath="url(#a)" id="fillTwitter">
      <path
        d="M39.212 23.539c.016.22.016.44.016.66 0 6.711-5.36 14.444-15.16 14.444-3.018 0-5.822-.833-8.181-2.28.429.048.841.064 1.287.064 2.49 0 4.783-.802 6.614-2.17-2.342-.046-4.305-1.508-4.981-3.52.33.047.66.079 1.006.079.478 0 .957-.063 1.402-.173-2.441-.472-4.272-2.515-4.272-4.982v-.063c.709.377 1.534.613 2.408.644-1.435-.912-2.375-2.467-2.375-4.228 0-.943.264-1.807.725-2.562 2.623 3.08 6.566 5.093 10.987 5.313a5.469 5.469 0 0 1-.132-1.163c0-2.798 2.375-5.077 5.328-5.077 1.534 0 2.92.613 3.893 1.603a10.812 10.812 0 0 0 3.381-1.226c-.396 1.18-1.237 2.17-2.342 2.798a11.097 11.097 0 0 0 3.068-.786 11.174 11.174 0 0 1-2.672 2.625Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M15.887 18.525h25.997V39.9H15.887z" />
      </clipPath>
    </defs>
  </svg>
)
export default TwitterSvg
