import React from "react";
import { styled } from '@mui/material/styles';
import { Box, Grid } from "@mui/material";
import TableRow from "./tableCards";
import ReactComponent from "./tableCards";
import TableCards from "./tableCards";
import CollapsibleTable from "./tablecontent";

export default function TableSection() {
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(2, 1),
        ...theme.mixins.toolbar,
    }));
    return (

        <>
            <Box component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                }} >

                <DrawerHeader />
                
<TableCards/>
<Grid item> 
                    <CollapsibleTable/>
                    </Grid>
            </Box>
        </>
    )
}
