import React from 'react';
import { Box, Grid } from '@mui/material';
import Activities from './activities';
import TopCallers from './topCallers';
function CallerActivity() {

    return (
        <>
            <Box
            sx={{
                marginTop:"30px"
            }}
            >
                <Grid container >
                    <Grid lg={5} md={5} sm={12} xs={12}
                        sx={{
                            paddingRight: {
                                lg: "30px",
                                md: "30px",
                                sm: "0px",
                                xs: "0px"
                            }
                        }}
                    >
                        <Activities />
                    </Grid>

                    <Grid lg={7} md={7} sm={12} xs={12}
                    sx={{
                        marginTop:{
                            lg:"unset",
                            md:"unset",
                            sm:"20px",
                            xs:"20px"
                        }
                    }}
                    >
                        <TopCallers />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default CallerActivity;
