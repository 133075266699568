// Dashboard.js

import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import "../../../assets/styles/variables.css"
import mark from "../../../assets/images/Mark.svg"

const textStyle = {
  color: "#ffffff",
  fontSize: "18px",
  fontFamily: "var(--font-family)",
  fontWeight: "600",
  lineHeight: "27px",
  paddingBottom: "30px"
}
const cardTextColor = {
  color: "#ffffff",
  fontSize: "24px",
  fontFamily: "var(--font-family)",
  fontWeight: "400",
  lineHeight: "21px",
  paddingBottom: "5px"
}
const cardTextdescription = {
  color: "#68676E",
  fontSize: "14px",
  fontFamily: "var(--font-family)",
  fontWeight: "400",
  lineHeight: "21px",


}
const StatisticSection = () => {

  return (
    <Grid container spacing={3} sx={{ padding: 1, marginTop: { lg: "20px", md: "30px", sm: "20px", xs: "20px" } }}>
      {/* Statistics Section */}
      <Grid item xs={12} sm={4} md={4} lg={3} >
        <Typography
          sx={{
            ...textStyle
          }}
        >
          Statistics
        </Typography>
        <Card sx={{ 
          backgroundColor: '#2D2F48',
           marginBottom: 3,
            borderRadius: "18px" ,
            }}>
          <CardContent
            sx={{ display: "flex", gap: "25px",alignItems:"center",
            padding:"16px 16px 16px !important"

            }}>
            <img src={mark}></img>
            <Box
            sx={{
              display:"flex",
              flexDirection:"column",
              position:"relative",
              top:"4px"
           
            }}
            >
              <Typography variant="h5" component="div"
                sx={{
                  ...cardTextColor,
                }}
              >
                32K
              </Typography>
              <Typography sx={{ ...cardTextdescription }} >
                Discord Members
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: '#2D2F48', marginBottom: 3, borderRadius: "18px" }}>
          <CardContent sx={{ display: "flex", gap: "25px",alignItems:"center",
                    padding:"16px 16px 16px !important"

        }}>
            <img src={mark}></img>
            <Box
               sx={{
                display:"flex",
                flexDirection:"column",
                position:"relative",
                top:"4px"
              }}
            >

              <Typography variant="h5" component="div"
                sx={{
                  ...cardTextColor,
                }}
              >
                32K
              </Typography>
              <Typography sx={{ ...cardTextdescription }} color="text.secondary" gutterBottom>
                Alpha Call

              </Typography>
            </Box>

          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: '#2D2F48', marginBottom: 3, borderRadius: "18px" }}>
          <CardContent sx={{ display: "flex", gap: "25px",alignItems:"center",
        
                    padding:"16px 16px 16px !important"
}}>
            <img src={mark}></img>

            <Box
               sx={{
                display:"flex",
                flexDirection:"column",
                position:"relative",
                top:"4px"
              }}
            >

              <Typography variant="h5" component="div"
                sx={{
                  ...cardTextColor,
                }}
              >
                32K
              </Typography>
              <Typography sx={{ ...cardTextdescription }} >
                Alpha Callers
              </Typography>
            </Box>

          </CardContent>
        </Card>
      </Grid>

      {/* ... other cards ... */}

      {/* Latest News Section */}
      <Grid item xs={12} sm={8} md={8} lg={9}>
        <Typography

          sx={{
            ...textStyle
          }}
        >
          Latest News
        </Typography>
        <Card sx={{ minWidth: "100%", backgroundColor: '#2D2F48', borderRadius: "18px" }}>
          <CardContent>
            <Typography>
              test
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StatisticSection;
