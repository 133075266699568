import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "../../assets/styles/variables.css"
import vector1 from "../../assets/images/vectorJoinOur1.svg"
import vector2 from "../../assets/images/vectorJoinOur2.svg"
import vector3 from "../../assets/images/vectorJoinOur3.svg"
import "./joinComunity.css"
const JoinComunity = () => {


    return (
        <>
            <Grid
                sx={{
                    marginTop: {
                        lg: "73px",
                        md: "73px",
                        sm: "73px",
                        xs: "0px"
                    },
                    paddingRight: {
                        lg: "120px",
                        md: "120px",
                        sm: "60px",
                        xs: "13px"

                    },
                    paddingLeft: {
                        lg: "120px",
                        md: "120px",
                        sm: "60px",
                        xs: "13px"
                    }
                }}
            >
                <Box
                    sx={{
                        background: "linear-gradient(112.62deg, #8D1CFE 29.4%, #0038ED 105.15%)",
                        borderRadius: "10px",
                        position: "relative"


                    }}
                >
                    <img
                        loading="lazy"
                        className="vecto1"
                        style={{
                            position: "absolute",
                        }}
                        src={vector1}
                        alt="Vector Image"
                    />

                    <Box
                        sx={{
                            display: {
                                lg: "unset",
                                md: "unset",
                                sm: "none",
                                xs: "none"
                            }
                        }}
                    >
                        <img
                            loading="lazy"
                            style={{
                                position: "absolute",
                                right: "0",
                                borderRadius: "10px",
                                height: "100%",
                            }} src={vector2} ></img>
                    </Box>




                    <Grid
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'inline-block',
                                flexDirection: 'column',
                                textAlign: 'center',
                                paddingTop: {
                                    lg: '70px',
                                },
                            }}
                        >
                            <Typography
                                sx={{

                                    color: '#fff',
                                    fontSize: {
                                        lg: '50px',
                                    },
                                    lineHeight: '75px',
                                    fontWeight: '700',
                                    fontFamily: 'var(--font-family)',
                                }}
                            >
                                Join Our Community
                            </Typography>
                            <Box
                                sx={{
                                    paddingBottom: "71px",
                                    paddingTop: "24px"

                                }}
                            >
                                <button
                                    style={{
                                        display: 'inline-block',
                                        padding: "16px",
                                        borderRadius: "66px",
                                        backgroundColor: "#FFFFFF",
                                        border: "#FFFFFF",
                                        color: "#1C51FE",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        fontFamily: 'var(--font-family)',
                                        lineHeight: "19px"

                                    }}
                                >
                                    Get Started
                                </button>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    position: "relative"
                                }}
                            >
                                <img
                                    loading="lazy"
                                    style={{
                                        position: "absolute",
                                        width: "100%"
                                    }}
                                    src={vector3}
                                    alt="Vector Image"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}

export default JoinComunity