import React, { useEffect, useState } from 'react';
import { Button, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import "../../../assets/styles/variables.css"
import HeaderName from '../../HeaderName/headerName';

import HeaderDashboardSection from '../../dashboardHeader/headerDashboardSection';
import CollapsibleTable from './tablecontent';


const textTitlestyle = {
    color: "#ffffff",
    fontWeight: "600",
    fontSize: "16px",
    fontFamily: "var(--font-family)"
}
const textSubscripestyle = {
    color: "#ffffff",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "var(--font-family)"
}

export default function TableCards() {
    const [value, setValue] = React.useState(2);
    const [pathname, setPathName] = useState('')
    useEffect(() => {
        const path = window.location.pathname;
        const trimmedPath = path.replace(/^\//, '');
        setPathName(trimmedPath)
    }, []);

    return (
        <>
            <HeaderDashboardSection
                title='Alpha Callers'
                // description="Alpha Callers"
                breadCrambs1="Home"
                breadCrambs2={pathname}
            />
            <Grid container spacing={3} sx={{ padding: 1, marginTop: { lg: "5px", md: "5px", sm: "5px", xs: "0px" } }}>
                {/* Statistics Section */}
                {/* <Grid item xs={12}>
                    <HeaderName name="Alpha Callers" />
                </Grid> */}
            
            </Grid>
        </>
    )
}

