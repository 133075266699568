import React  from "react";
import { Box, Grid, Typography, Button, Paper, Divider } from '@mui/material';
import userimg from "../../../../assets/images/profileBg.svg"
const textTitlestyle={
    color:"#ffffff",
    fontWeight:"600",
    fontSize:"16px",
    fontFamily:"var(--font-family)"
}
const textSubscripestyle={
    color:"#ffffff",
    fontWeight:"400",
    fontSize:"14px",
    fontFamily:"var(--font-family)"
}
const textTimestyle={
    color:"#68676E",
    fontWeight:"400",
    fontSize:"14px",
    fontFamily:"var(--font-family)"
}
function Activities(){
    const activities = [
        { name: 'Papaya', title: 'Recently Call for #NFT', time: '12 mins ago' },
        { name: 'Papaya', title: 'Recently Call for #NFT', time: '12 mins ago' },
        { name: 'Papaya', title: 'Recently Call for #NFT', time: '12 mins ago' },

        // ... other activities
    ]

    return(
        <>
         <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom:"30px"

                            }}
                        >
                            <Typography
                                sx={{
                                    color: "#FFFFFF",
                                    fontSize:"18px",
                                    fontWeight:"600",
                                    fontFamily:"var(--font-family)",
                                    lineHeight:"27px"

                                }}
                            >
                                Recent Activity
                            </Typography>
                            <Button 
                             sx={{
                                color:"#6F4FF2",
                                fontSize:"14px",
                                fontWeight:"400",
                                lineHeight:"21px",
                                fontFamily:"var(--font-family)",
                                textTransform:"unset"
                                }}
                            >
                                See More
                            </Button>
                        </Box>
                        <Box sx={{ background: "#1D1932", borderRadius: "18px" }}>
                            {activities.map((item, index) => (
                                <React.Fragment key={index}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",

                                            paddingLeft: {
                                                lg:'20px',
                                                md:'20px',
                                                sm:'10px',
                                                xs:'10px'
                                            },
                                            paddingRight: {
                                                lg:'20px',
                                                md:'20px',
                                                sm:'10px',
                                                xs:'10px'
                                            },
                                            paddingTop: "20px", 
                                            paddingBottom: index === activities.length - 1 ? "20px" : "0", // Apply bottom padding only to the last item
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: "16px",
                                            }}
                                        >
                                           
                                                <img src={userimg} alt="User"></img>
                                          
                                            <Box>
                                                <Typography
                                                    sx={{
                                                       ...textTitlestyle
                                                    }}
                                                >
                                                    {item.name}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                       ...textSubscripestyle
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography
                                        sx={{
                                            ...textTimestyle
                                        }}
                                        >{item.time}</Typography>
                                    </Box>
                                    {index !== activities.length - 1 && <Divider sx={{ marginTop: "15px",background:"#68676E" }} />}
                                </React.Fragment>
                            ))}
                        </Box>
        </>
    )
}
export default Activities