import React, { useEffect, useState } from "react";
import Discover from "./discover";
import JoinComunity from "./joinComunity";
import HowItWork from "./howItWork";
import Faq from "./faq";
import axios from 'axios';


const LandingPage = () => {
  const [userData, setUserData] = useState([]);

  const getData = () => {
    axios.get('http://localhost:3001/getUserData')
      .then(userDataResponse => {
        const userDataFromAPI = userDataResponse.data;
        console.log('User data from API:', userDataFromAPI);
        setUserData(userDataFromAPI);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  };


  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Discover />
      <JoinComunity />
      <HowItWork />
      <Faq />
    </>
  )
}

export default LandingPage