import React, { useEffect, useState } from 'react';
import { Button, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import "../../../assets/styles/variables.css"
import mark from "../../../assets/images/Mark.svg"
import HeaderName from '../../HeaderName/headerName';
import profileimg from "../../../assets/images/profileBg.svg"
import HeaderDashboardSection from '../../dashboardHeader/headerDashboardSection';
const textTitlestyle = {
    color: "#ffffff",
    fontWeight: "600",
    fontSize: "16px",
    fontFamily: "var(--font-family)"
}
const textSubscripestyle = {
    color: "#ffffff",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "var(--font-family)"
}
export default function ProfileInputs() {
    const [pathname, setPathName] = useState('')
    useEffect(() => {
        const path = window.location.pathname;
        const trimmedPath = path.replace(/^\//, '');
        setPathName(trimmedPath)
    }, []);

    return (
        <>

            <HeaderDashboardSection
                title={pathname}
                description="Welcome Setting Page"
                breadCrambs1="Home"
                breadCrambs2={pathname}
            />
            <Grid container spacing={3} sx={{ padding: 1,marginTop: { lg: "5px", md: "5px", sm: "5px", xs: "0px" } }}>


                <Grid item lg={6} md={6} sm={12} xs={12}           >
                    <HeaderName name="User Profile" />

                    <Box
                        sx={{
                            background: "#1D1932",
                            padding: "20px",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            marginTop: "20px",

                        }}
                    >
                        <Typography
                            sx={{
                                ...textTitlestyle
                            }}
                        >
                            Full name
                        </Typography>
                        <input type='text'
                            style={{
                                background: "#131129",
                                border: "none",
                                height: "40px",
                                width: "100%",
                                borderRadius: "8px",
                                padding: "8px",
                                color: "white",


                            }}
                        ></input>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center"
                            }}
                        >
                            <img src={profileimg}></img>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px"
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...textSubscripestyle
                                    }}
                                >
                                    Abdalrahman sh
                                </Typography>
                                <Typography
                                    sx={{
                                        ...textSubscripestyle
                                    }}
                                >
                                    Welcome Setting Page
                                </Typography>
                            </Box>
                        </Box>
                        <Button
                            sx={{
                                background: "#6F4FF2",
                                color: "#ffffff",
                                fontFamily: "var(--font-family)",
                                borderRadius: "9px",
                                maxWidth: "90px"
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <HeaderName name="Update Profile" />

                    <Box
                        sx={{
                            background: "#1D1932",
                            padding: "20px",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "11px",
                            marginTop: "20px",
                            width: "100%"
                        }}
                    >
                        <Typography
                            sx={{
                                ...textTitlestyle
                            }}
                        >
                            Email
                        </Typography>
                        <input type='text'
                            style={{
                                background: "#131129",
                                color: "white",
                                border: "none",
                                height: "40px",
                                width: "100%",
                                borderRadius: "8px",
                                padding: "8px"
                            }}
                        ></input>
                        <Typography
                            sx={{
                                ...textTitlestyle
                            }}
                        >
                            Password
                        </Typography>
                        <input type='text'
                            style={{
                                background: "#131129",
                                border: "none",
                                height: "40px",
                                width: "100%",
                                borderRadius: "8px",
                                padding: "8px",
                                color: "white",


                            }}
                        ></input>
                        <Button
                            sx={{
                                background: "#6F4FF2",
                                color: "#ffffff",
                                fontFamily: "var(--font-family)",
                                borderRadius: "9px",
                                maxWidth: "90px"
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
                <Grid item lg={12} xs={12} >
                    <HeaderName name="Personal Information" />
                    <Grid>
                        <Grid item container
                            sx={{
                                background: "#1D1932",
                                padding: "20px",
                                borderRadius: "8px",
                                marginTop: "20px",

                            }}
                        >
                            <Grid lg={6} md={6} sm={12} xs={12}
                                sx={{
                                    paddingRight: {
                                        lg: "10px",
                                        md: "10px",
                                        sm: "0px",
                                        xs: "0px"
                                    },
                                    marginBottom: "20px"

                                }}
                            >
                                <Typography
                                    sx={{
                                        ...textTitlestyle,
                                        marginBottom: "10px"
                                    }}
                                >
                                    Solana Wallet
                                </Typography>
                                <input type='text'
                                    style={{
                                        background: "#131129",
                                        border: "none",
                                        height: "40px",
                                        width: "100%",
                                        borderRadius: "8px",
                                        padding: "8px",
                                        color: "white",


                                    }}
                                ></input>
                            </Grid>
                            <Grid lg={6} md={6} sm={12} xs={12}
                                sx={{
                                    marginBottom: "20px"

                                }}
                            >
                                <Typography
                                    sx={{
                                        ...textTitlestyle,
                                        marginBottom: "10px"

                                    }}
                                >
                                    Discord
                                </Typography>
                                <input type='text'
                                    style={{
                                        background: "#131129",
                                        border: "none",
                                        height: "40px",
                                        width: "100%",
                                        borderRadius: "8px",
                                        padding: "8px",
                                        color: "white",


                                    }}
                                ></input>
                            </Grid>
                            <Grid lg={6} md={6} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        ...textTitlestyle,
                                        marginBottom: "10px"

                                    }}
                                >
                                    Twitter
                                </Typography>
                                <input type='text'
                                    style={{
                                        background: "#131129",
                                        border: "none",
                                        height: "40px",
                                        width: "100%",
                                        borderRadius: "8px",
                                        padding: "8px",
                                        color: "white",



                                    }}
                                ></input>
                            </Grid>
                            <Grid lg={12} md={12} sm={12} xs={12}>
                                <Button
                                    sx={{
                                        background: "#6F4FF2",
                                        color: "#ffffff",
                                        fontFamily: "var(--font-family)",
                                        borderRadius: "9px",
                                        maxWidth: "90px",
                                        marginTop: "36px"
                                    }}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}