import React from "react";
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';

const textStyle = {
    color: "#ffffff",
    fontSize: "18px",
    fontFamily: "var(--font-family)",
    fontWeight: "600",
    lineHeight: "27px",
}
export default function HeaderName({ name }) {

    return (
        <>
            <Typography
                sx={{
                    ...textStyle
                }}
            >
                {name}
            </Typography>
        </>
    )
}