import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import discover from "../../assets/images/Discover.svg"
import cover from "../../assets/images/Cover.svg"
import "../../assets/styles/variables.css"
import DiscordSvg from "../../assets/images/discord";
import TwitterSvg from "../../assets/images/twitter";
import face from "../../assets/images/Face.svg"
import faceBg from "../../assets/images/faceBg.svg";
import {GoogleLogin} from "react-google-login"
import LoginGoogle from "../login/googleLogin";
import LoginModal from "../login/loginModal";

const Discover = () => {
 
  return (
    <>
      <Grid container
        sx={{
          marginTop: {
            lg: "91px",
            xs: "40px"
          },
          paddingRight: {
            lg: "79px",
            xs: "13px"
          },
          paddingLeft: {
            lg: "66px",
            xs: "13px"
          },

        }}
      >
        <Grid lg={6} md={7} sm={7} xs={12}>
          <Box
            sx={{
              position: "relative",
              // minHeight:{
              //   lg: "320px",
              //   md: "340px",
              //   sm:"340px",
              //   xs:"300px"
              // }, 
              backgroundImage: `url(${discover})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "21px"
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: `url(${cover}) no-repeat center center / cover`,
                borderRadius: "21px"

              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                color: '#fff',
                fontFamily: "var(--font-family)",
                paddingRight: "17px",
                paddingLeft: "25px",


              }}
            >
              <Typography
                sx={{
                  position: "relative",
                  fontSize: {
                    lg: "50px",
                    md: "50px",
                    sm: "30px",
                    xs: "20px",
                  },
                  left: {
                    lg: '30px',
                    md: '30px',
                    sm: '30px',
                    xs: '10px',
                  },
                  marginTop:"29px"
                }}
              >
                Easy way
              </Typography>

              <Typography
                sx={{
                  fontSize: {
                    lg: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "16px"
                  },
                  lineHeight: "30px",
                  position: "relative",
                  marginTop: "25px",
                }}
              >
                Elevate your alpha-caller status with Birneo, the unique platform connecting alpha-callers and project founders. From the web to Discord, energize your community with Birneo!
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: "46px",
                  paddingBottom: "25px", // Adjusted padding to separate the button from the bottom

                }}
              >
                 {/* <LoginGoogle/> */}
                 <LoginModal/>

              </Box>

            </Box>

          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "24px",
              marginTop: "24px",
              marginRight: "24px",
            }}
          >
            <DiscordSvg />
            <TwitterSvg />
          </Box>
        </Grid>


        <Grid lg={5.8} md={5} sm={5} xs={12}
          sx={{
            display: "flex",
            justifyContent: {
              lg: "end",
              md: "end",
              sm: "end",
              xs: "center"
            },
          }}
        >
          <img loading="lazy" src={face} style={{ width: "100%", maxWidth: "471.44px" }}></img>
          <img loading="lazy" style={{ position: "absolute", top: "0", right: "0", zIndex: '-1' }} src={faceBg}></img>
        </Grid>
      </Grid>
    </>
  )
}

export default Discover