import React from "react";
import StatisticSection from "./statisticsSection";
import CallerActivity from "./activityCaller/activityCaller";
import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';

function DashboardLayout() {
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(2, 1),
        ...theme.mixins.toolbar,
    }));

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1 ,p:3}}>
                <DrawerHeader />
                <StatisticSection />
                <CallerActivity />
            </Box>

        </>
    )
}

export default DashboardLayout