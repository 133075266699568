import * as React from "react"
const SvgDashboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#6F4FF2"
      d="M10.984.563c-.52 0-.89.408-.89.89v5.938c0 .52.37.89.89.89h7.125a.88.88 0 0 0 .891-.89V1.453a.903.903 0 0 0-.89-.89h-7.126Zm-2.968 0H.89a.88.88 0 0 0-.891.89v5.938c0 .52.371.89.89.89h7.126a.88.88 0 0 0 .89-.89V1.453a.903.903 0 0 0-.89-.89ZM0 10.358v5.938c0 .52.371.89.89.89h7.126a.88.88 0 0 0 .89-.89v-5.938a.903.903 0 0 0-.89-.89H.89a.88.88 0 0 0-.891.89Zm10.984 6.829h7.125a.88.88 0 0 0 .891-.891v-5.938a.903.903 0 0 0-.89-.89h-7.126c-.52 0-.89.408-.89.89v5.938c0 .52.37.89.89.89Z"
    />
  </svg>
)
export default SvgDashboard
