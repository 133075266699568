import * as React from "react"
import "./discord.css"
const DiscordSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={58}
    height={57}
    fill="none"
    id="fill"
    {...props}
  >
    <rect width={57.772} height={57} fill="#F3F3F3" rx={28.5} />
    <g id="fill" clipPath="url(#a)">
      <path
      id="fill"
        d="M33.134 27.788c0 .869-.668 1.581-1.515 1.581-.832 0-1.515-.712-1.515-1.581 0-.87.668-1.582 1.515-1.582.847 0 1.515.712 1.515 1.582Zm-6.937-1.582c-.847 0-1.516.712-1.516 1.582 0 .869.684 1.581 1.516 1.581.847 0 1.515-.712 1.515-1.581.015-.87-.668-1.582-1.515-1.582Zm15.687-9.02V42.75c-3.742-3.172-2.545-2.122-6.893-6l.788 2.637H18.931c-1.678 0-3.045-1.311-3.045-2.935V17.186c0-1.625 1.367-2.936 3.045-2.936H38.84c1.678 0 3.045 1.311 3.045 2.936ZM37.65 30.695c0-4.589-2.139-8.308-2.139-8.308-2.14-1.54-4.174-1.497-4.174-1.497l-.208.229c2.525.74 3.699 1.81 3.699 1.81-3.53-1.856-7.674-1.856-11.097-.414-.55.242-.877.413-.877.413s1.233-1.126 3.907-1.867l-.148-.17s-2.036-.043-4.175 1.496c0 0-2.139 3.719-2.139 8.308 0 0 1.248 2.066 4.531 2.166 0 0 .55-.642.995-1.183-1.886-.542-2.6-1.682-2.6-1.682.22.147.58.337.61.357 2.507 1.346 6.069 1.788 9.27.498.52-.185 1.099-.456 1.708-.84 0 0-.743 1.168-2.689 1.695a61.6 61.6 0 0 0 .98 1.155c3.284-.1 4.546-2.166 4.546-2.166Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M15.887 14.25h25.997v28.5H15.887z" />
      </clipPath>
    </defs>
  </svg>
)
export default DiscordSvg
