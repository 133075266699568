import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './component/navbar/navbar';
import LandingPage from './component/landingPage/landigpage';
import Footer from './component/footer/footer';
import MiniDrawer from './component/dashboard/sidebar';
import DashboardLayout from './component/dashboard/dashboardSection/dashboardLayout';
import List from "./component/dashboard/listSection/list";
import Profile from "./component/dashboard/profileSection/Profile";
import FavoriteSection from "./component/dashboard/favorite/favoriteSection";
import TableSection from "./component/dashboard/table/table";

// Your gapi and clientId setup

function App() {
  const [showNav, setShowNav] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowNav(location.pathname === '/');
  }, [location]);

  return (
    <>
      {showNav && <NavBar />}
      <div style={{ display: 'flex' }}>
        {!showNav && <MiniDrawer />}
        <div style={{ flexGrow: 1 ,overflowX: 'auto'}}>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/dashboard' element={<DashboardLayout />} />
            <Route path='/list' element={<List />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/Subscription' element={<FavoriteSection />} />
            <Route path='/Table' element={<TableSection />} />


          </Routes>
        </div>
      </div>
      {showNav && <Footer />}
    </>
  );
}

export default App;
