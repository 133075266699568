import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from "../../assets/images/marketAlphaLogo.svg"
import "../../assets/styles/variables.css"
import { useNavigate } from 'react-router-dom';

export default function NavBar() {
    const navigate=useNavigate()
    const navigateToDahsboard=()=>{
        navigate('/dashboard')
    }
    return (
        <Box sx={{
            flexGrow: 1,
            paddingRight: {
                lg: "79px",
                xs: "13px"
            },
            paddingLeft: {
                lg: "66px",
                xs: "0px"
            }
        }}>
            <AppBar position="static" sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                <Toolbar sx={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Box
                        sx={{
                            maxWidth: {
                                lg: "300px",
                                md: "300px",
                                sm: "300px",
                                xs: "200px"
                            },

                        }}
                    >
                        <img loading='lazy' width="100%" src={logo}></img>

                    </Box>
                    <Button onClick={navigateToDahsboard} color="inherit"
                        sx={{
                            border: "1px solid var(--blue-color)",
                            borderRadius: "9px",
                            width: "100%",
                            maxWidth: {
                                lg: "165px",
                                md: "165px",
                                sm: "165px",
                                xs: "120px"
                            },
                            height: "100%",
                            maxHeight: "49px",
                            color: "#ffffff",
                            fontWeight: "600",
                            lineHeight: "37.5px",
                            textAlign: "center",
                            fontSize: { lg: "25px", md: "25px", sm: "25px", xs: "20px" },
                            fontFamily: "var(--font-family)"
                        }}
                    >Sign In</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
