import React  from "react";
import { Box, Grid, Typography, Button, Paper, Divider } from '@mui/material';
import userimg from "../../../../assets/images/profileBg.svg"
import "../../../../assets/styles/variables.css"
const textTitlestyle={
    color:"#ffffff",
    fontWeight:"600",
    fontSize:"16px",
    fontFamily:"var(--font-family)"
}
const textSubscripestyle={
    color:"#ffffff",
    fontWeight:"400",
    fontSize:"14px",
    fontFamily:"var(--font-family)"
}

const ButtonStyle={
    color:"#ffffff",
    fontSize:"14px",
    fontFamily:"var(--font-family)",
    fontWeight:"600",
 
}
function TopCallers(){
    const topCallers = [
        { name: 'Papaya', subscribers: "60 subscribes" },
        { name: 'Papaya', subscribers: "60 subscribes" },
        { name: 'Papaya', subscribers: "60 subscribes" },
        { name: 'Papaya', subscribers: "60 subscribes" },
        { name: 'Papaya', subscribers: "60 subscribes" },
        { name: 'Papaya', subscribers: "60 subscribes" },
    ];

    return(
        <>
                <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                            paddingBottom:"30px"

                        }}
                        >
                            <Typography
                           sx={{
                            color: "#FFFFFF",
                            fontSize:"18px",
                            fontWeight:"600",
                            fontFamily:"var(--font-family)",
                            lineHeight:"27px"

                        }}
                            >
                            Top Alpha Caller
                            </Typography>
                            <Button 
                             sx={{
                                color:"#6F4FF2",
                                fontSize:"14px",
                                fontWeight:"400",
                                lineHeight:"21px",
                                fontFamily:"var(--font-family)",
                                textTransform:"unset"

                                }}>
                                See More
                            </Button>
                        </Box>

                        <Box
    sx={{
        display: 'flex',
        justifyContent: 'space-between', // Space evenly between the two sides
        flexWrap: 'wrap', // Allow items to wrap onto the next line
        gap:{
            lg:"30px",
            md:"30px",
            sm:"20px",
            xs:"0px"
        }
    }}
>
    {/* Left side container */}
    <Box sx={{ flex: '1 0 45%' }}> {/* Adjust the width as needed */}
        {topCallers.map((item, index) => (
            index % 2 === 0 && (
                <Box
                    key={index}
                    sx={{
                        background: '#1D1932',
                        borderRadius: '18px',
                        paddingLeft: {
                            lg:'20px',
                            md:'20px',
                            sm:'10px',
                            xs:'10px'
                        },
                        paddingRight: {
                            lg:'20px',
                            md:'20px',
                            sm:'10px',
                            xs:'10px'
                        },
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        marginBottom: '15px',
                        display: 'flex',
                        gap: '15px',
                        alignItems: 'center',
                        justifyContent:"space-between"
                    }}
                >
                    <Box
                    sx={{
                        display:"flex",
                        gap:"15px"
                    }}
                    >
                    <img src={userimg} />
                    <Box>
                        <Typography
                        sx={{
                            ...textTitlestyle
                        }}
                        >{item.name}</Typography>
                        <Typography
                        sx={{
                            ...textSubscripestyle
                        }}
                        >{item.subscribers}</Typography>
                    </Box>
                    </Box>
                    <Box
                    sx={{
                        paddingLeft:"13px",
                        paddingRight:"13px",
                        border:"1px solid #6F4FF2 ",
                        borderRadius:"9px",
                    }}
                    > 
                    <Button color="primary"
                    sx={{
                        ...ButtonStyle
                    }}
                    >
                               More
                            </Button>
                            </Box>
                </Box>
            )
        ))}
    </Box>
    
    {/* Right side container */}
    <Box sx={{ flex: '1 0 45%' }} rowGap="20px"> {/* Adjust the width as needed */}
        {topCallers.map((item, index) => (
            index % 2 !== 0 && (
                <Box
                key={index}
                sx={{
                    background: '#1D1932',
                    borderRadius: '18px',
                    paddingLeft: {
                        lg:'20px',
                        md:'20px',
                        sm:'10px',
                        xs:'10px'
                    },
                    paddingRight: {
                        lg:'20px',
                        md:'20px',
                        sm:'10px',
                        xs:'10px'
                    },
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    marginBottom: '15px',
                    display: 'flex',
                    gap: '15px',
                    alignItems: 'center',
                    justifyContent:"space-between"
                }}
            >
                <Box
                sx={{
                    display:"flex",
                    gap:"15px"
                }}
                >
                <img src={userimg} />
                <Box>
                    <Typography
                    sx={{
                        ...textTitlestyle
                    }}
                    >{item.name}</Typography>
                    <Typography
                    sx={{
                        ...textSubscripestyle
                    }}
                    >{item.subscribers}</Typography>
                </Box>
                </Box>
                <Box
                    sx={{
                        paddingLeft:"13px",
                        paddingRight:"13px",
                        border:"1px solid #6F4FF2 ",
                        borderRadius:"9px",
                    }}
                    > 
                <Button 
                sx={{
                    ...ButtonStyle,
                }}
                >
                            More
                        </Button>
                        </Box>
            </Box>
            )
        ))}
    </Box>
</Box>

        </>
    )
}

export default TopCallers