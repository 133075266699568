// Dashboard.js
import React, { useEffect, useState } from 'react';
import { Button, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import "../../../assets/styles/variables.css"
import mark from "../../../assets/images/Mark.svg"
import HeaderName from '../../HeaderName/headerName';
import CardImg from "../../../assets/images/cardImg.svg"
import Rating from '@mui/material/Rating';
import HeaderDashboardSection from '../../dashboardHeader/headerDashboardSection';

const cards = [
    { name: "Ceejil", img: CardImg, btnText: "See More", Reviews: "Reviews", Bot: "Currently Bot", endOn: " 02/15/2024 at 10:31 p.m" },

]
const textTitlestyle = {
    color: "#ffffff",
    fontWeight: "600",
    fontSize: "16px",
    fontFamily: "var(--font-family)"
}
const textSubscripestyle = {
    color: "#ffffff",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "var(--font-family)"
}

export default function PremuimCards() {
    const [value, setValue] = React.useState(2);
    const [pathname, setPathName] = useState('')
    useEffect(() => {
        const path = window.location.pathname;
        const trimmedPath = path.replace(/^\//, '');
        setPathName(trimmedPath)
    }, []);
    return (
        <>
            <HeaderDashboardSection
                title={pathname}
                description="Welcome Setting Page"
                breadCrambs1="Home"
                breadCrambs2={pathname}
            />
            <Grid container spacing={3} sx={{ padding: 1, marginTop: { lg: "5px", md: "5px", sm: "5px", xs: "0px" } }}>
                {/* Statistics Section */}
                <Grid item xs={12}>
                    <HeaderName name="Alpha Callers" />
                </Grid>
                {cards.map((item) => (
                    <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
                        <Card sx={{ backgroundColor: '#2D2F48', marginBottom: 3, borderRadius: "18px", padding: "20px" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                <img width="100%" src={item.img} alt={item.name} />
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography sx={{ ...textTitlestyle }}>{item.name}</Typography>
                                    <Button sx={{ color: "#6F4FF2", fontSize: "14px", fontWeight: "400", lineHeight: "21px", fontFamily: "var(--font-family)", textTransform: "unset" }}>
                                        {item.btnText}
                                    </Button>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography sx={{ ...textSubscripestyle }}>{item.Reviews}</Typography>
                                    <Rating name="read-only" value={value} readOnly size='small' />
                                </Box>

                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography sx={{ ...textSubscripestyle }}>End On{item.endOn}</Typography>

                                </Box>
                                <Button
                                    sx={{
                                        background: "#6F4FF2",
                                        color: "#ffffff",
                                        fontFamily: "var(--font-family)",
                                        borderRadius: "9px",
                                        fontSize: "15px",
                                        fontWeight: "600"
                                    }}
                                >
                                    Resubscription
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}