import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import footerBg from "../../assets/images/Footer.svg"
import DiscordSvg from "../../assets/images/discord";
import TwitterSvg from "../../assets/images/twitter";
import "../../assets/styles/variables.css"

const Footer = () => {
    return (
        <>
            <Grid
                sx={{
                    marginTop: "128px",
                }}
            >
                <Box
                    sx={{
                        backgroundImage: `url(${footerBg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        paddingBottom: "64px",
                        paddingTop: {
                            lg: "100px",
                            md: "100px",
                            sm: "100px",
                            xs: "0px"
                        }
                    }}
                >
                    <Grid container
                        sx={{
                            paddingRight: {
                                lg: "95px",
                                md: "95px",
                                sm: "45px",
                                xs: "24px"
                            },
                            paddingLeft: {
                                lg: "95px",
                                md: "95px",
                                sm: "45px",
                                xs: "24px"
                            },
                            paddingTop: "197px",
                        }}
                    >
                        <Grid lg={3} md={3} sm={4} xs={6}                >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "12px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#ffffff",
                                        fontSize: "50px",
                                        fontWeight: "600",
                                        fontFamily: "var(--font-family)",

                                    }}
                                >
                                    LOGO
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "var(--font-family)",
                                        fontSize: "20px",
                                        fontWeight: "300"

                                    }}
                                >
                                    © Birneo 2024
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid lg={4} md={4} sm={3} xs={6} container
                            sx={{
                                alignItems: "center",
                                justifyContent: {
                                    lg: "unset",
                                    md: "unset",
                                    sm: "unset",
                                    xs: "end"
                                },
                                paddingTop: {
                                    xs: "50px"
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "12px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "var(--font-family)",
                                        fontSize: "20px",
                                        fontWeight: "300"
                                    }}
                                >
                                    Terms
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "var(--font-family)",
                                        fontSize: "20px",
                                        fontWeight: "300",
                                        lineHeight: "30px"
                                    }}
                                >
                                    Privacy
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid lg={5} md={5} sm={5} xs={12} container
                            sx={{
                                alignItems: "center",
                                justifyContent: {
                                    lg: "end",
                                    md: "end",
                                    sm: "end",
                                    xs: "center"
                                },
                                marginTop: {
                                    xs: "40px"
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "12px"
                                }}
                            >
                                <TwitterSvg />
                                <DiscordSvg />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}
export default Footer