import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import "../../assets/styles/variables.css"
import step1 from "../../assets/images/Wallet.svg"
import linear from "../../assets/images/linearSteps.svg"

const HowItWork = () => {
    const HowWork = [
        { name: "Set up your Account", img: step1, linear: linear },
        { name: "Add Your Alpha Caller", img: step1, linear: linear },
        { name: "Add Birneo BOT", img: step1, linear: linear },
        { name: "Receive Alpha Call", img: step1 }
    ]
    return (
        <>
            <Grid
                sx={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "Center",
                    flexDirection: "column",
                    marginBottom: "40px", //to be deleted
                    marginTop: {
                        lg: "124px",
                        md: "124px",
                        sm: "80px",
                        xs: "80px"
                    },

                }}
            >
                <Typography
                    sx={{
                        color: "#ffffff",
                        fontSize: {
                            lg: "64px",
                            md: "64px",
                            sm: "42px",
                            xs: "32px"
                        },
                        fontWeight: "500",
                        lineHeight: "130px",
                        fontFamily: "var(--font-family)",
                        textAlign: "center"


                    }}
                >
                    How it works
                </Typography>

                <Grid container lg={12}
                    sx={{
                        justifyContent: "center"
                    }}
                >
                    {HowWork.map((item, index) => (
                        <>
                            <Grid lg={1.5} md={1.6} sm={4} xs={12} container justifyContent="center"
                                sx={{
                                    gap: "30px",
                                    marginTop: "30px"
                                }}
                            >

                                <img
                                loading="lazy"
                                    style={{
                                        maxWidth: "100px",
                                        width: "100%"
                                    }}
                                    src={item.img}></img>
                                <Typography
                                    sx={{
                                        color: "white",
                                        width: {
                                            lg: "unset",
                                            md: "unset",
                                            sm: "unset",
                                            xs: "100%"
                                        },
                                        whiteSpace: "nowrap",
                                        textAlign: "center",
                                        fontWeight: "700",
                                        fontSize: {
                                            lg: "22px",
                                            md: "20px",
                                            sm: "22px",
                                            xs: "22px"
                                        }
                                    }}
                                >
                                    {item.name}
                                </Typography>
                            </Grid>

                            <Grid lg={1.5} md={1.5} sm={3} xs={12}
                                sx={{
                                    position: "relative",
                                    bottom: {
                                        lg: "0px",
                                        md: "0px",
                                        sm: "0px"
                                    },
                                    display: {
                                        lg: index === 3 ? "none" : "unset",
                                        md: index === 3 ? "none" : "unset",
                                        sm: (index === 1 && "none") || (index === 3 && "none") || "unset",
                                        xs: "none",

                                    },
                                    left: {
                                        lg: "0px",
                                        md: "0px",
                                        sm: "0px"
                                    }
                                }}
                            >
                                {index <= 3 && (
                                    <img
                                    loading="lazy"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        src={item.linear}
                                    />
                                )}
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Grid>
        </>
    )
}

export default HowItWork